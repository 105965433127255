.app_container {
    display: flex;
    justify-content: space-between;
}

.column {
    padding: 20px;
    border: 5px solid #d8dcee;
}

/* ::selection {
    background: #72a24d;
} */