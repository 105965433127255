@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;

  color: #ebe7ef;
  background: radial-gradient(#dae1ec, #ffffff);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}


header {
  text-align: center;
  margin: 3rem 0;
}

header img {
  height: 5rem;
  width: 10rem;
  object-fit: cover;
}

header h1 {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 5rem;
  background: linear-gradient(40deg, #0084ff, #00d9ff, #03d5ff, #03d5ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));
}

header p {
  margin: 0;
  font-size: 1.25rem;
  color: #8964b0;
  font-family: "Roboto Condensed", sans-serif;
}

main {
  width: 90%;
  max-width: 50rem;
  margin: auto;
}

#core-concepts {
  padding: 2rem;
  border-radius: 6px;
  background-color: #140524;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1.5rem 0;
  color: #a18aba;
}

#core-concepts ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

#core-concepts li {
  width: 10rem;
  text-align: center;
}

#core-concepts li img {
  height: 4rem;
  width: 6rem;
  object-fit: cover;
}

#core-concepts li h3 {
  margin: 0.5rem 0;
}

#core-concepts li p {
  font-size: 0.9rem;
}

#examples {
  margin: 3rem auto;
}

#examples h2 {
  text-align: left;
}

#examples menu {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  list-style: none;
}

#examples menu button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: #a18aba;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#examples menu button:hover {
  background-color: #1b082f;
  color: #efebe7;
}


#examples menu button.active {
  background-color: #7925d3;
  color: #ebe7ef;
}


#tab-content {
  padding: 1rem;
  border-radius: 6px;
  background-color: #2f1d43;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

#tab-content h3 {
  margin: 0;
}

#tab-content code {
  font-size: 1rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 1rem 0;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #145bdf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #145bdf transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-block {
  background-color: #f0d9e5;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 4px;
  color: #890b35;
  display: flex;
  gap: 2rem;
  align-items: center;
  text-align: left;
}

.error-block-icon {
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  color: #fff;
  background-color: #890b35;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-block h2 {
  color: inherit;
  font-size: 1.25rem;
  margin: 0;
}

.error-block p {
  margin: 0;
}

.center {
  text-align: center;
}