.email_card {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

/*
email card active is inline css in EmailsList.js : (line 8 col 179)
*/

.email_card:hover {
    background: #c0c1c2;
}

.email_card .sender {
    font-weight: bold;
}

.email_card .created_time {
    font-size: 12px;
    color: #8b8b8b;
}

.email_card .subject {
    margin-top: 5px;
}