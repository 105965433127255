.completed_email_form {
    border: 5px solid #1b345a;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
    background-color: #efeff3;
}

.completed_email_card {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.completed_email_card .bold {
    font-weight: bold;
    font-size: 20px;
}

.completed_email_card .grey-text {
    font-size: 16px;
    color: #666;
}

.completed_email_card .title {
    font-size: 18px;
    font-weight: bold;
    color: #666;
}

.completed_email_card .top-on-gap {
    margin-top: 5px;
}

.completed_email_card .bot-gap {
    margin-bottom: 10px;
}

.completed_email_form .completed_wagon_form {
    border: 5px solid #546886;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
    background-color: #efeff3;
}

.button-container {
    display: flex;
    gap: 15px;
    /* Adjust the value to set the desired space */
}

.editable-container {
    border: 1px solid #ccc;
    padding: 5px;
    min-height: 30px;
    outline: none;
}