.my-modal {
    margin: 0;
    padding: 2rem;
    position: relative;
    top: 10vh;
    left: calc(50% - 20rem);
    width: 60rem;
    max-height: 100vh;
    background: #e2e5eb;
    border: none;
    border-radius: 6px;
    z-index: 100;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: slide-down-fade-in 300ms ease-out forwards;
}

.rightmost-corner-element {
    position: absolute;
    top: 0;
    /* Adjust the top position as needed */
    right: 0;
    /* This will position the element at the rightmost corner */
}


/* body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.my-modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
} */