.input-container {
    border: 5px solid #4482e0;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
    background-color: #e9f3f3;
}

.wagon_textarea {
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px;
    border: 10px solid #ccc;
    border-radius: 4px;
    border: 1px solid #66758b;
}

.wagon_textarea_input {
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    width: 430px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #66758b;
    resize: vertical;
    font-family: inherit;
}


.invalid-feedback {
    color: red;
    border: 1px solid red;
    font-size: 14px;
}