.custom_textarea {
    width: 250px;
    height: 75px;
}

.scrollable_div {
    height: 800px;
    width: 280px;
    overflow: auto;
    border: 1px solid #ccc;
}

.scrollable_history_completed {
    height: 800px;
    width: 800px;
    overflow: auto;
    border: 1px solid #ccc;
}

.scrollable_history-emails {
    height: 800px;
    width: 350px;
    overflow: auto;
    border: 1px solid #ccc;
}

#complete-email-form input {
    margin-bottom: 2%;
    border: 1px solid #4482e0;
}

/* 
#complete-email-form textarea {
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px;
    border: 10px solid #ccc;
    border-radius: 4px;
    border: 1px solid #66758b;
} */

.remarks_textarea {
    margin-bottom: 2%;
    /* display: flex; */
    flex-direction: column;
    height: 100px;
    width: 500px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #66758b;
}

.remarks_textarea_create {
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    height: 5%;
    width: 80%;
    padding: 8px;
    border: 10px solid #ccc;
    border-radius: 4px;
    border: 1px solid #66758b;
}


.complete_email_form {
    border: 5px solid #1b345a;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
    background-color: #efeff3;
}

.complete_email_form_create {
    border: 5px solid #1b345a;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 3%;
    background-color: #efeff3;
    width: 70%;
}

.main {
    margin-top: 5%;
    margin-bottom: 9%;
}

.main label {
    font-weight: bold;
}

input {
    height: 30px;
    width: 90%;
}