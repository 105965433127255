.containerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2e5eb;
}

.serverErrors {
    display: grid;
    justify-content: center;
    align-items: center;
    background: #eececd;
    color: red;
}