.custom_textarea {
    width: 250px;
    height: 75px;
}

.scrollable_div {
    height: 70%;
    width: 500px;
    /* width: 100%; */
    overflow: auto;
    border: 1px solid #ccc;
}

.scrollable_history_completed {
    height: 800px;
    width: 800px;
    overflow: auto;
    border: 1px solid #ccc;
}

.scrollable_history-emails {
    height: 800px;
    width: 350px;
    overflow: auto;
    border: 1px solid #ccc;
}

#complete-email-form input {
    margin-bottom: 2%;
    border: 1px solid #4482e0;
}

#complete-email-form textarea {
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px;
    border: 10px solid #ccc;
    border-radius: 4px;
    border: 1px solid #66758b;
}